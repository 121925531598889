import { authApi } from '../utils/authapi'
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export function login(userName, password) {
    return authApi(`/auth/login`, {
        method: 'POST',
        body: {
            user_name: userName,
            password: password
        }
    });
}

export function adminlogin(userName, password) {
    return authApi(`/auth/admin-login`, {
        method: 'POST',
        body: {
            user_name: userName,
            password: password
        }
    });
}

export function checkOldPassword(payload) {
    return authApi(`/auth/change/check`, {
        method: 'POST',
        body: payload
    });
}

export function changePassword(payload) {
    return authApi(`/auth/change/password`, {
        method: 'POST',
        body: payload
    });
}

export function checkOldPasswordUsers(payload) {
    return authApi(`/auth/change/user-check`, {
        method: 'POST',
        body: payload
    });
}

export function changePasswordUsers(payload) {
    return authApi(`/auth/change/user-password`, {
        method: 'POST',
        body: payload
    });
}

export function ping() {
    return authApi(`/auth/ping`, {
        method: 'GET'
    });
}

export function getFlags() {
    return authApi(`/auth/flag`, {
        method: 'GET'
    });
}

export function logout() {
    return authApi(`/auth/signout`, {
        method: 'GET'
    });
}

export function verifyEmail(token) {
    return authApi(`/auth/verify-email?token=${token}`, {
        method: 'GET'
    });
}

export function activateClient(clientId) {
    return authApi(`/auth/activate-client?clientId=${clientId}`, {
        method: 'GET'
    });
}

export function sendReverification(clientId) {
    return authApi(`/auth/send-verification?clientId=${clientId}`, {
        method: 'GET'
    });
}

export function uploadFiles(formData) {
    return authApi(`/attachment/uploadfile`, {
        method: "POST",
        body: formData
    })
}

export function uploadPartnerRecipt(formData) {
    return authApi(`/attachment/uploadPartnerRecipt`, {
        method: "POST",
        body: formData
    })
}

export function tokenExpiry() {
    let token = Cookies.get('ap_auth_token');

    if (!token || token === 'null' || token === '') {
        return true;
    } else {
        let decodedToken = jwtDecode(token);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            return true;
        } else {
            return false;
        }
    }
}

export function forgotPassword(payload) {
    return authApi(`/auth/forgot-password`, {
        method: 'POST',
        body: payload
    });
}

export function resetPassword(payload) {
    return authApi(`/auth/reset-password`, {
        method: 'POST',
        body: payload
    });
}

export function validateClientToken(token) {
    return authApi(`/clients/verify-client?token=${token}`, {
        method: 'GET'
    });
}

export function searchPartnerRegister(token, key) {
    return authApi(`/register/search_partner?token=${token}&key=${key}`, {
        method: 'GET'
    });
}

export function getPartnerInfo(token, key) {
    return authApi(`/register/get-partner-info?token=${token}&key=${key}`, {
        method: 'GET'
    });
}

export function uploadRegPartnerRecipt(formData) {
    return authApi(`/register/uploadPartnerRecipt`, {
        method: "POST",
        body: formData
    })
}

export function savePartnerInfo(payload) {
    return authApi(`/register/partner`, {
        method: "POST",
        body: payload
    })
}

export function documentReupload(docId, payload) {
    return authApi(`/register/document-reupload?docId=${docId}`, {
        method: "POST",
        body: payload
    })
}

export function partnerInfo(partnerId) {
    return authApi(`/register/partner-info?partnerId=${partnerId}`, {
        method: 'GET'
    });
}

export function getMyRegDocumentsLists(partnerId) {
    return authApi(`/register/my_reg_document_list?partnerId=${partnerId}`, {
        method: 'GET'
    });
}

export function approveRegPartnerDocument(payload) {
    return authApi(`/register/reg_approve_document`, {
        method: 'POST',
        body: payload
    });
}

export function getMyDocumentList(partnerId) {
    return authApi(`/register/my_document_list?partnerId=${partnerId}`, {
        method: 'GET'
    });
}

export function documentUpload(payload) {
    return authApi(`/register/document`, {
        method: 'POST',
        body: payload
    });
}